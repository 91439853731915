import React, { useMemo, useRef } from 'react';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';
// Selectors
import { useSelector } from 'react-redux';
import { useHatiko } from '../PromotionHatico/useHatiko';
import {
  isAstronautTheme,
  isMovie10Theme,
  isMovieBorderTheme,
  isMoviePaddingtonTheme,
} from '../../state/modules/city/selectors';

// Assets
import emptyCart from './assets/empty.jpg';
import emptyCart2x from './assets/empty@2x.jpg';
import emptyCart3x from './assets/empty@3x.jpg';
import hatikoBin from './assets/hatico_bin.png';
import cosmoBin from '../../assets/Astronaut_images/cosmonout.png';
import movieBin from '../PromotionMovie10/assets/4.png';

// Styles
import styles from './Cart.styl';
import PromotionMovieBorderDecor from '../PromotionMovieBorder/PromotionMovieBorderDecor';
import PromotionMoviePaddingtonDecor from '../PromotionMoviePaddington/PromotionMoviePaddingtonDecor';

const cx = classNames.bind(styles);

const Empty = () => {
  const { isHatiko } = useHatiko();
  const isAstronaut = useSelector(isAstronautTheme);
  const isMovieTheme = useSelector(isMovie10Theme);
  const isMovieBorder = useSelector(isMovieBorderTheme);
  const isMoviePaddington = useSelector(isMoviePaddingtonTheme);

  const binImage = useMemo(() => {
    if (isHatiko) {
      return <img src={hatikoBin} alt="empty cart" />;
    }
    if (isMovieTheme) {
      return <img src={movieBin} width={240} height={190} alt="empty" />;
    }

    if (isMoviePaddington) {
      return (
        <PromotionMoviePaddingtonDecor
          srcView="4"
          width={240}
          height={190}
          alt="empty cart"
          style={{ position: 'static' }}
        />
      );
    }
    return <img src={emptyCart} srcSet={`${emptyCart2x} 2x, ${emptyCart3x} 3x`} alt="empty cart" />;
  }, [isHatiko, isMovieTheme, isMoviePaddington]);

  return (
    <div className={cx('Cart__empty')}>
      <div className={cx('Cart__empty-pic', { 'Cart__empty-pic-hatiko': isHatiko })}>
        {isAstronaut && (
          <img
            src={cosmoBin}
            width={88}
            height={116}
            className={cx('Cart__empty-pic-cosmo')}
            alt="decor"
          />
        )}

        {binImage}

        {isMovieBorder && (
          <PromotionMovieBorderDecor srcView="2" className={cx('Cart__empty-pic__promoBorder')} />
        )}
      </div>
      <div>
        <FormattedMessage id="cart.empty" />
      </div>
    </div>
  );
};

export default Empty;
